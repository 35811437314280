<template>
    <modal ref="modalHistorialMovimiento" titulo="Historial de movimientos del producto" tamano="modal-lg" no-aceptar no-cancelar cerrar>
        <div class="row mx-0 px-4">
            <img :src="producto.imagen" class="obj-cover br-8 border" width="100px" height="100px" />
            <div class="col-6">
                <p class="text-general nombre">
                    {{ producto.nombre }}
                </p>
                <p class="mt-2 f-16 text-general2">{{ producto.presentacion }}</p>
                <p class="mt-2 f-16 text-general f-600"> {{ separadorNumero(producto.valor) }} Valor actual</p>
                <p class="mt-2 f-16 text-general f-600">{{ producto.stock_actual }} Stock actual</p>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <el-table
                :data="historial"
                style="width: 100%"
                header-cell-class-name="text-general f-16 f-500 bg-white"
                >
                    <el-table-column width="130" label="Movimiento">
                        <template slot-scope="{row}">
                            <div class="row mx-0 align-items-center">
                                <div class="box-table d-middle-center" :class="row.tipo == 2 ? 'bg-green' : 'bg-general'">
                                    <i :class="`f-12 text-white ${row.tipo == 2 ? 'icon-plus' : 'icon-minus' } `" />
                                </div>
                                <div class="col">
                                    {{ row.tipo == 1 ? 'Salida' : 'Ingreso' }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Unidades" width="120" align="center">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                {{ agregarSeparadoresNumero(row.cantidad_afectada) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Valor Movimiento" min-width="180" align="center">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                {{ separadorNumero(row.valor_movimiento) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Fecha" min-width="140">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                {{ row.created_at | helper-fecha('DD-MMM-YYYY') }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="responsable" label="Responsable" min-width="180" />
                    <el-table-column width="60">
                        <template slot-scope="{row}">
                            <div class="mx-0">
                                <el-tooltip :content="row.comentario" placement="bottom" effect="light">
                                    <i class="icon-message-text-outline text-general f-20" />
                                </el-tooltip>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
import Inventarios from "~/services/inventarios/lecheros"
export default {
    props:{
        idLechero: {
            type: [String,Number],
            default:''
        }
    },
    data(){
        return {
            producto:{},
            historial:[]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalHistorialMovimiento.toggle();
        },
        showHistorial(idProducto){
            this.getHistorialProducto(idProducto)
            this.toggle()
        },
        async getHistorialProducto(idProducto){
            try {

                const {data} = await Inventarios.getHistorialProducto(idProducto, this.idLechero)
                this.historial = data.historial
                this.producto = data.producto

            } catch (error){
                this.error_catch(error)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.box-table{
    width: 18px;
    height: 18px;
    border-radius: 4px;
}
</style>
