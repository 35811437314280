var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modalHistorialMovimiento",attrs:{"titulo":"Historial de movimientos del producto","tamano":"modal-lg","no-aceptar":"","no-cancelar":"","cerrar":""}},[_c('div',{staticClass:"row mx-0 px-4"},[_c('img',{staticClass:"obj-cover br-8 border",attrs:{"src":_vm.producto.imagen,"width":"100px","height":"100px"}}),_c('div',{staticClass:"col-6"},[_c('p',{staticClass:"text-general nombre"},[_vm._v(" "+_vm._s(_vm.producto.nombre)+" ")]),_c('p',{staticClass:"mt-2 f-16 text-general2"},[_vm._v(_vm._s(_vm.producto.presentacion))]),_c('p',{staticClass:"mt-2 f-16 text-general f-600"},[_vm._v(" "+_vm._s(_vm.separadorNumero(_vm.producto.valor))+" Valor actual")]),_c('p',{staticClass:"mt-2 f-16 text-general f-600"},[_vm._v(_vm._s(_vm.producto.stock_actual)+" Stock actual")])])]),_c('div',{staticClass:"row mx-0 mt-3"},[_c('div',{staticClass:"col-12 px-0"},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.historial,"header-cell-class-name":"text-general f-16 f-500 bg-white"}},[_c('el-table-column',{attrs:{"width":"130","label":"Movimiento"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"row mx-0 align-items-center"},[_c('div',{staticClass:"box-table d-middle-center",class:row.tipo == 2 ? 'bg-green' : 'bg-general'},[_c('i',{class:("f-12 text-white " + (row.tipo == 2 ? 'icon-plus' : 'icon-minus') + " ")})]),_c('div',{staticClass:"col"},[_vm._v(" "+_vm._s(row.tipo == 1 ? 'Salida' : 'Ingreso')+" ")])])]}}])}),_c('el-table-column',{attrs:{"label":"Unidades","width":"120","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mx-0"},[_vm._v(" "+_vm._s(_vm.agregarSeparadoresNumero(row.cantidad_afectada))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Valor Movimiento","min-width":"180","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mx-0"},[_vm._v(" "+_vm._s(_vm.separadorNumero(row.valor_movimiento))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Fecha","min-width":"140"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mx-0"},[_vm._v(" "+_vm._s(_vm._f("helper-fecha")(row.created_at,'DD-MMM-YYYY'))+" ")])]}}])}),_c('el-table-column',{attrs:{"prop":"responsable","label":"Responsable","min-width":"180"}}),_c('el-table-column',{attrs:{"width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"mx-0"},[_c('el-tooltip',{attrs:{"content":row.comentario,"placement":"bottom","effect":"light"}},[_c('i',{staticClass:"icon-message-text-outline text-general f-20"})])],1)]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }